import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import ArticleItemPressRelease from 'src/pages/Blog/components/PressRelease/ArticleItemPressRelease';

import styles from './press-releases.less';

const TrlKeys = {
    noArticles: 'blog.articles.list.noArticles',
};

const BlogPressReleaseGrid: TranslatedComponent = ({ trls }) => {
    const mainArticles = useSelector(({ blogPage }) => blogPage?.articles?.main) ?? [];

    if (!mainArticles.length) {
        return <p className={styles.pressReleaseListEmpty}>{trls[TrlKeys.noArticles]}</p>;
    }

    return (
        <div className={styles.pressReleasesContent}>
            {mainArticles.map((item) => (
                <ArticleItemPressRelease item={item} key={item.code} />
            ))}
        </div>
    );
};

export default translation(BlogPressReleaseGrid);
