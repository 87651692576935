import { MouseEvent, useCallback, useEffect } from 'react';
import classnames from 'classnames';

import { SPALink, usePush } from '@hh.ru/redux-spa-middleware';
import Button from 'bloko/blocks/button';
import { FormSpacer } from 'bloko/blocks/form';
import { KeyCode } from 'bloko/common/constants/keyboard';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import urlParser from 'bloko/common/urlParser';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { ExtremePage, NextOrPrevPage, NumberPage, Paging } from 'src/models/paging.types';

const TrlKeys = {
    firstPage: 'pager.goToFirstPage',
    nextPage: 'pager.readMore',
};

const getPageUrl = (page: number, href: string): string => {
    const urlObject = urlParser(href);
    urlObject.params.page = [page];
    return urlObject.href;
};

export interface PaginationProps extends Paging {
    /** При смене страницы выполняется этот обработчик, если он задан, вместо изменения url **/
    changePageHandler?: (page: number) => void;
    /** При формировании ссылок страниц выполняется этот обработчик, если он задан, вместо дефолтного getPageUrl **/
    getGenericPageUrl?: (page: number) => string;
    /** Формировать ссылки страниц, доступные для индексации поисковыми роботами **/
    isSeoPaging?: boolean;
}

const Pagination: TranslatedComponent<PaginationProps> = ({
    pages,
    next,
    previous,
    firstPage,
    lastPage,
    os,
    changePageHandler,
    getGenericPageUrl,
    isSeoPaging,
    trls,
}) => {
    const location = useSelector((state) => state.router.location);
    const push = usePush();

    const linkProps = isSeoPaging ? { isSeoLink: true } : { rel: 'nofollow' };

    const getChangePageHandler = (page: number) => {
        if (!changePageHandler) {
            return undefined;
        }
        return (event: MouseEvent) => {
            event.preventDefault();
            changePageHandler(page);
        };
    };

    const keyDownHandler = useCallback(
        (event: KeyboardEvent) => {
            // Если фокус на инпуте, не блокируем возможность сочетанием [alt + →] перемещаться по нему курсором
            if (document.activeElement?.tagName.toLowerCase() === 'input') {
                return;
            }

            const metaKeyCode = os === 'Mac' ? 'altKey' : 'ctrlKey';

            let newPage;
            if (event[metaKeyCode] && KeyCode.ArrowLeft === event.keyCode) {
                newPage = previous.page;
            }
            if (event[metaKeyCode] && KeyCode.ArrowRight === event.keyCode) {
                newPage = next.page;
            }

            if (newPage) {
                if (changePageHandler) {
                    changePageHandler(newPage);
                } else {
                    const url = getGenericPageUrl
                        ? getGenericPageUrl(newPage)
                        : getPageUrl(newPage, location.pathname + location.search);
                    push(url);
                }
            }
        },
        [changePageHandler, getGenericPageUrl, location.pathname, location.search, next, os, previous, push]
    );

    useEffect(() => {
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [keyDownHandler]);

    const renderPageButton = ({ text, selected, page, inShortRange }: NumberPage) => {
        if (text === '...') {
            return null;
        }
        const url = getGenericPageUrl ? getGenericPageUrl(page) : getPageUrl(page, location.pathname + location.search);
        return (
            <span
                className={classnames({ 'pager-item-not-in-short-range': !inShortRange })}
                data-qa={`pager-page-wrapper-${text}-${page}`}
                key={`${text}-${page}`}
            >
                {selected ? (
                    <Button Element="span" pressed onClick={getChangePageHandler(page)} data-qa="pager-page">
                        {text}
                    </Button>
                ) : (
                    <Button
                        Element={SPALink}
                        to={url}
                        {...linkProps}
                        onClick={getChangePageHandler(page)}
                        data-qa="pager-page"
                    >
                        {text}
                    </Button>
                )}
                <FormSpacer Element="span" />
            </span>
        );
    };

    const renderNextButton = ({ page, disabled }: NextOrPrevPage) => {
        if (disabled) {
            return null;
        }
        const url = getGenericPageUrl ? getGenericPageUrl(page) : getPageUrl(page, location.pathname + location.search);
        return (
            <Button Element={SPALink} to={url} {...linkProps} onClick={getChangePageHandler(page)} data-qa="pager-next">
                {trls[TrlKeys.nextPage]}
            </Button>
        );
    };

    const renderFirstPageButton = ({ page }: ExtremePage) => {
        const url = getGenericPageUrl ? getGenericPageUrl(page) : getPageUrl(page, location.pathname + location.search);
        return (
            <span className="pager-item-not-in-short-range">
                <Button
                    Element={SPALink}
                    to={url}
                    {...linkProps}
                    onClick={getChangePageHandler(page)}
                    data-qa="first-page"
                >
                    {trls[TrlKeys.firstPage]}
                </Button>
                <FormSpacer Element="span" />
            </span>
        );
    };

    const renderLastPageButton = ({ page, selected }: ExtremePage) => {
        return (
            <span className="pager-item-not-in-short-range">
                <span data-qa="pager-block-dots">...</span>
                <FormSpacer Element="span" />
                {renderPageButton({ page, text: `${page + 1}`, selected })}
            </span>
        );
    };

    if (!pages || !next) {
        return null;
    }

    return (
        <div className="pager" data-qa="pager-block">
            {firstPage && renderFirstPageButton(firstPage)}
            {pages.map(renderPageButton)}
            {lastPage && renderLastPageButton(lastPage)}
            {renderNextButton(next)}
        </div>
    );
};

export default translation(Pagination);
