import { useCallback, useEffect, useRef, useState, FC } from 'react';
import { useRouteMatch } from 'react-router-dom';
import classnames from 'classnames';

import { BarsOutlinedSize24, MagnifierOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import { ClickDown, MenuItem } from 'bloko/blocks/drop';
import { DownPlacement } from 'bloko/blocks/drop/Down/common';
import BlokoLink, { LinkKind } from 'bloko/blocks/link';
import Text from 'bloko/blocks/text';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';

import BlokoIconReplaceContainer from 'src/components/BlokoIconReplaceContainer';
import { useSelector } from 'src/hooks/useSelector';
import { BlogDictionaryItem } from 'src/models/blogPage';

import SearchForm from 'src/pages/Blog/components/Header/SearchForm';
import useFocusOnAppearance from 'src/pages/Blog/components/Header/useFocusOnAppearance';

import styles from './blog-header.less';

interface BlogDesktopMenuProps {
    menuItems: BlogDictionaryItem[];
}

const BlogDesktopMenu: FC<BlogDesktopMenuProps> = ({ menuItems }) => {
    const match = useRouteMatch<{ rubric: string; topic: string }>();
    const isSearchEnabled = useSelector(({ blogPage }) => blogPage.isSearchEnabled);
    const breakpoint = useBreakpoint(Breakpoint.L);
    const [mainMenuItems, setMainMenuItems] = useState(menuItems);
    const [dropdownMenuItems, setDropdownMenuItems] = useState<BlogDictionaryItem[]>([]);
    const [showSearchBar, setShowSearchBar] = useState(false);
    const isShowDropdownMenu = dropdownMenuItems.length > 0;
    const containerRef = useRef<HTMLDivElement>(null);
    const fakeMenuRef = useRef<HTMLDivElement>(null);
    const fakeButtonRef = useRef<HTMLDivElement>(null);
    const focusOnAppearanceRef = useFocusOnAppearance();
    const constantIconButtonsCount = isSearchEnabled ? 1 : 0;
    const activatorLupaRef = useRef(null);
    const activatorBarsRef = useRef(null);

    const determineMainAndDropdownMenusComposition = useCallback(() => {
        if (!containerRef.current || !fakeMenuRef.current || !fakeButtonRef.current) {
            return;
        }

        const fakeMenuWidth = fakeMenuRef.current.getBoundingClientRect().width;
        const containerWidth = containerRef.current.getBoundingClientRect().width;
        const fakeButtonWidth = fakeButtonRef.current.getBoundingClientRect().width;

        const menuWidthWithBurger = containerWidth - (constantIconButtonsCount + 1) * fakeButtonWidth;
        const menuWidthWithoutBurger = containerWidth - constantIconButtonsCount * fakeButtonWidth;

        if (fakeMenuWidth <= menuWidthWithoutBurger) {
            setMainMenuItems(menuItems);
            setDropdownMenuItems([]);
        }

        let mainMenuCount = 0;
        let tmpMenuWidth = 0;
        let menuComplete = false;
        (fakeMenuRef.current.childNodes as never as HTMLAnchorElement[]).forEach((node) => {
            if (!menuComplete && tmpMenuWidth + node.clientWidth < menuWidthWithoutBurger) {
                tmpMenuWidth += node.clientWidth;
                mainMenuCount += 1;
            } else {
                menuComplete = true;
            }
        });

        if (mainMenuCount < menuItems.length && tmpMenuWidth > menuWidthWithBurger) {
            mainMenuCount -= 1;
        }

        setMainMenuItems(menuItems.slice(0, mainMenuCount));
        setDropdownMenuItems(menuItems.slice(mainMenuCount));
    }, [constantIconButtonsCount, menuItems]);

    useEffect(() => {
        if (breakpoint !== Breakpoint.XS) {
            determineMainAndDropdownMenusComposition();
        }
    }, [breakpoint, determineMainAndDropdownMenusComposition]);

    if (!menuItems.length) {
        return null;
    }

    const renderMainMenuItem = (item: BlogDictionaryItem) => {
        const isActive = match && (match.params.rubric === item.code || match.params.topic === item.code);
        const href = item.code === '_blog_index' ? '/articles' : `/articles/${item.code}`;

        return (
            <div
                className={classnames(styles.menuItem, {
                    [styles.menuItemActive]: isActive,
                })}
                key={item.code}
            >
                <BlokoLink Element={SPALink} to={href} kind={isActive ? undefined : LinkKind.Tertiary} disableVisited>
                    <Text>{item.name}</Text>
                </BlokoLink>
            </div>
        );
    };

    const renderDropdownMenuItem = (item: BlogDictionaryItem) => {
        const isActive = match && (match.params.rubric === item.code || match.params.topic === item.code);
        const href = item.code === '_blog_index' ? '/articles' : `/articles/${item.code}`;

        return (
            <div key={item.code}>
                {isActive ? (
                    <MenuItem>
                        <span className={styles.menuItemActive}>{item.name}</span>
                    </MenuItem>
                ) : (
                    <BlokoLink Element={SPALink} to={href} kind={LinkKind.Tertiary}>
                        <MenuItem>
                            <Text>{item.name}</Text>
                        </MenuItem>
                    </BlokoLink>
                )}
            </div>
        );
    };

    return (
        <div className={styles.container} ref={containerRef}>
            <div className={styles.fakeMenu} ref={fakeMenuRef}>
                {menuItems.map(renderMainMenuItem)}
            </div>
            <div className={styles.fakeButton} ref={fakeButtonRef}>
                <BlokoIconReplaceContainer>
                    <BarsOutlinedSize24 />
                </BlokoIconReplaceContainer>
            </div>
            <div className={styles.menu}>
                {mainMenuItems.map(renderMainMenuItem)}
                {isSearchEnabled && (
                    <ClickDown
                        onlySetPlacement
                        flexible
                        placement={DownPlacement.BottomEnd}
                        show={showSearchBar}
                        onClose={() => setShowSearchBar(false)}
                        render={() => <SearchForm ref={focusOnAppearanceRef} />}
                        activatorRef={activatorLupaRef}
                    >
                        <div className={styles.interactiveButton} ref={activatorLupaRef}>
                            <BlokoIconReplaceContainer>
                                <MagnifierOutlinedSize24
                                    onClick={() => {
                                        setShowSearchBar(!showSearchBar);
                                    }}
                                />
                            </BlokoIconReplaceContainer>
                        </div>
                    </ClickDown>
                )}
                {isShowDropdownMenu && (
                    <ClickDown
                        onlySetPlacement
                        placement={DownPlacement.BottomEnd}
                        render={() =>
                            dropdownMenuItems.map((item) => (
                                <div key={item.code} className={styles.menuItemWrapper}>
                                    {renderDropdownMenuItem(item)}
                                </div>
                            ))
                        }
                        activatorRef={activatorBarsRef}
                    >
                        <div className={styles.interactiveButton} ref={activatorBarsRef}>
                            <BlokoIconReplaceContainer>
                                <BarsOutlinedSize24 />
                            </BlokoIconReplaceContainer>
                        </div>
                    </ClickDown>
                )}
            </div>
        </div>
    );
};

export default BlogDesktopMenu;
