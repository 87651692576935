import { FC } from 'react';

import { SPALink } from '@hh.ru/redux-spa-middleware';
import { H3 } from 'bloko/blocks/header';
import BlokoLink, { LinkKind } from 'bloko/blocks/link';
import Text, { TextImportance, TextSize } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';

import ArticleEventDates from 'src/components/ArticleEventDates';
import { BlogArticle } from 'src/models/blogPage';

import { useGetArticleHref } from 'src/components/ArticleItem/utils/get-article-properties';

import styles from './article-list-item-event.less';

interface Props {
    item: BlogArticle;
}

const ArticleListItemEvent: FC<Props> = ({ item }) => {
    const articleHref = useGetArticleHref(item);

    return (
        <div className={styles.event}>
            <div className={styles.eventDate}>
                <Text size={TextSize.Small} importance={TextImportance.Tertiary}>
                    <ArticleEventDates
                        eventStartDate={item.eventStartDate}
                        eventStartDateShort={item.eventStartDateShort}
                        eventEndDate={item.eventEndDate}
                    />
                </Text>
            </div>
            <div className={styles.eventContent}>
                <H3>
                    <BlokoLink Element={SPALink} to={articleHref} kind={LinkKind.Tertiary}>
                        {item.title}
                    </BlokoLink>
                </H3>
                <VSpacing base={1} />
                {item.announce}
            </div>
        </div>
    );
};

export default ArticleListItemEvent;
