import { FC, PropsWithChildren } from 'react';

import { SPALink } from '@hh.ru/redux-spa-middleware';
import BlokoLink, { LinkKind } from 'bloko/blocks/link';
import Vspacing from 'bloko/blocks/vSpacing';

import HumanDate, { ShowYear } from 'src/components/HumanDate';
import { BlogArticle } from 'src/models/blogPage';

import { useGetArticleHref } from 'src/components/ArticleItem/utils/get-article-properties';

import styles from './article-list-item.less';

interface Props {
    item: BlogArticle;
}

const ArticleListItemMedium: FC<Props & PropsWithChildren> = ({ item }) => {
    const { title, eventStartDate, publicationDate } = item;
    const articleHref = useGetArticleHref(item);
    const date = eventStartDate || publicationDate;

    return (
        <div>
            <BlokoLink Element={SPALink} to={articleHref} kind={LinkKind.Tertiary} title={title}>
                {title}
                <Vspacing base={1} />
                <div className={styles.date}>
                    <HumanDate date={date} showYear={ShowYear.NotCurrent} />
                </div>
            </BlokoLink>
        </div>
    );
};

export default ArticleListItemMedium;
