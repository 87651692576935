import { memo } from 'react';
import classNames from 'classnames';

import { SPALink } from '@hh.ru/redux-spa-middleware';
import BlokoLink, { LinkKind } from 'bloko/blocks/link';
import Text, { TextSize } from 'bloko/blocks/text';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import {
    ArticleRubric,
    ArticleRubrics,
    ArticleTopic,
    ArticleTag as ArticleTopicTag,
} from 'src/models/articlePage/types';

import styles from './tag.less';

interface ArticleTagProps {
    code: ArticleTopicTag | ArticleTopic;
    hhtmFromLabel?: string;
    isTopic?: boolean;
    isLarge?: boolean;
}

const RubricColors: Record<ArticleRubric, string | null> = {
    [ArticleRubrics.Applicants]: styles.tagRed,
    [ArticleRubrics.Employers]: styles.tagBlue,
    [ArticleRubrics.Students]: styles.tagGreen,
    [ArticleRubrics.LabourMarket]: styles.tagYellow,
    [ArticleRubrics.SiteNews]: styles.tagGrey,
    [ArticleRubrics.Insider]: styles.tagViolet,
    [ArticleRubrics.OwnBusiness]: styles.tagOrange,
    [ArticleRubrics.Specs]: null,
    [ArticleRubrics.Events]: null,
};

const DEFAULT_RUBRIC_COLOR = styles.tagGrey;

const ArticleTag: TranslatedComponent<ArticleTagProps> = ({
    code,
    hhtmFromLabel,
    isTopic = false,
    isLarge = false,
}) => {
    const { rubrics, rubric, topic } = useSelector(({ blogPage }) => blogPage);
    let currentRubricData;
    let name;
    let link;

    if (isTopic) {
        currentRubricData = rubrics.find((rubric) => rubric.topics.find((topic) => topic.code === code));
        name = currentRubricData?.topics.find((topic) => topic.code === code)?.name;
        link = currentRubricData && `/articles/${currentRubricData.code}/${code}`;
    } else {
        currentRubricData = rubrics.find((rubricItem) => rubricItem.code === rubric);
        name = currentRubricData?.topics
            .find((topicItem) => topicItem.code === topic)
            ?.tags.find((tag) => tag.code === code)?.name;
        link = currentRubricData && name && `/articles/search?query=${name}&rubric=${currentRubricData.code}`;
    }

    if (!currentRubricData || !name || !link) {
        return null;
    }

    return (
        <BlokoLink
            disableVisited
            kind={LinkKind.Tertiary}
            Element={SPALink}
            to={link}
            additionalQueryParams={{
                hhtmFromLabel,
            }}
        >
            <div
                className={classNames(styles.tag, RubricColors[currentRubricData.code] || DEFAULT_RUBRIC_COLOR, {
                    [styles.tagLarge]: isLarge,
                })}
            >
                {isLarge ? <Text size={TextSize.Large}>{name}</Text> : name}
            </div>
        </BlokoLink>
    );
};

export default translation(memo(ArticleTag));
