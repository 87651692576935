import { FC, PropsWithChildren } from 'react';

import { SPALink } from '@hh.ru/redux-spa-middleware';
import BlokoLink, { LinkKind } from 'bloko/blocks/link';

interface Props {
    isActive: boolean;
    href: string;
}

const BlogSubMenuLink: FC<Props & PropsWithChildren> = ({ children, isActive, href }) => {
    if (isActive) {
        return <>{children}</>;
    }
    return (
        <BlokoLink Element={SPALink} to={href} kind={LinkKind.Tertiary}>
            {children}
        </BlokoLink>
    );
};

export default BlogSubMenuLink;
